<template>
  <NavBar />
  <div class="row" style="height: 8vh;"></div>
  <div class="login-header">
    <div class="row" style="height: 8vh;"></div>
    <div class="row" style="height: 76vh">
      <div class="col-1"></div>
      <div class="col-4" style="background-color: #EDF2EF; border-radius: 15px 0 0 15px">
        <div style="height: 4vh;"></div>
        <img src="../assets/storyset/Login.gif" style="width: 30vw; margin-left: 2.5vw;">
      </div>
      <div class="col-3" style="background-color: #EDF2EF;">
        <p class="text-h2" style="font-family: Montserrat; font-weight: 700; margin-top: 4vh; margin-bottom: 1vh; margin-right: 5vw">zerudite</p>
        <p class="text-h4 lato">welcomes you back!</p>
        <p class="text-h4 lato" style="margin-right: 5vw;">Ready to start learning?</p>

      </div>
      <div class="col-3 login-dialog">
        <p class="text-h4 text-white" style="margin-left: 2.5vw; margin-top: 4vh; font-family: Lato">Log In</p>
        <q-scroll-area style="height: 50vh; margin-left: 2.5vw;">
          <q-form style="margin-right: 2vw;" @submit="onSubmit()">
            <p class="lato text-white" style="margin-bottom: 0px; font-size: 14px">Email</p>
            <q-input color="teal-10" bg-color="white" outlined v-model="emailText" label="Enter your email" style="font-family: Lato;" type="email" :rules="[val => val.length > 0 || 'Please enter your email address', emailValidator]" label-color="grey-7"/>

            <p class="lato text-white" style="margin-bottom: 0px; font-size: 14px; margin-top: 1vh;">Password</p>
            <q-input color="teal-10" bg-color="white" outlined v-model="passwordText" label="Enter your password" style="font-family: Lato;" type="password" :rules="[val => val.length > 0 || 'Please enter your password']"/>

            <q-btn no-caps push label="Log In" style="background-color: #A8D0E6; border-radius: 20px; width: 8vw; white-space: nowrap; color: black; font-family: Montserrat; font-weight: 600; margin-top: 2vh;" type="submit" label-color="grey-7"   />

          </q-form>
          <q-btn no-caps push label="Forgot Password?" style="background-color: #CBDFBD; border-radius: 20px; width: 12vw; white-space: nowrap; color: black; font-family: Montserrat; font-weight: 600; margin-top: 2vh;" label-color="grey-7" @click="forgotPassword" />
        </q-scroll-area>
      </div>
      <div class="col-1"></div>
    </div>
  </div>

  <q-dialog v-model="forgotDialog" no-esc-dismiss no-backdrop-dismiss>
    <q-card flat bordered style="width: 40vw">
      <q-card-section>
        <div class="text-h5 q-mb-xs-xs" style="font-family: Lato">Forgot Password</div>
      </q-card-section>
      <div>
        <q-form @submit="resetPassword">
          <q-separator />
          <q-card-section style="padding-top: 0.5vh; margin-top: 1vh">
            <q-input color="teal-10" bg-color="white" outlined v-model="forgotEmail" label="Email" label-color="teal-10" style="font-family: Lato;" :rules="[val => val.length > 0 || 'Please enter your email address!', emailValidator]" />




          </q-card-section>

          <q-separator />

          <q-card-actions align="right">
            <q-btn flat label="Cancel Password Reset" color="red-10" v-close-popup style="font-family: Lato" no-caps />
            <q-btn push label="Reset Password" color="teal-10" type="submit" style="font-family: Lato" no-caps/>

          </q-card-actions>
        </q-form>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import NavBar from "../components/NavBar.vue"
import {getCurrentInstance, ref} from 'vue'
import firebase from 'firebase'
import {Dialog} from "quasar";

export default {
  setup () {
    var emailText = ref("")
    var passwordText = ref("")
    const internalInstance = getCurrentInstance()
    var forgotDialog = ref(false)
    var forgotEmail = ref("")

    return {
      emailText,
      passwordText,
      internalInstance,
      forgotDialog,
      forgotEmail
    }
  },
  methods: {
    emailValidator () {
      const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      return emailPattern.test(this.emailText) || 'Please enter a valid email address';
    },
    async onSubmit () {
      try {
        await firebase.auth().signInWithEmailAndPassword(this.emailText, this.passwordText)
      } catch (e) {
        console.log(e.code)
        let message
        switch (e.code) {
          case 'auth/user-not-found':
            message = 'There is no user corresponding to the provided email.'
            break
          case 'auth/wrong-password':
            message = 'Incorrect password provided.'
            break
          default:
            message = e.message
        }
        Dialog.create({
          title: 'An error occurred',
          message: message,
          persistent: true
        }).onOk(() => {
          // console.log('OK')
        }).onCancel(() => {
          // console.log('Cancel')
        }).onDismiss(() => {
          // console.log('I am triggered on both OK and Cancel')
        })
        return
      }
      let user = firebase.auth().currentUser

      let url = this.internalInstance.appContext.config.globalProperties.$backendURL
      let IDToken = await user.getIdToken(true)

      let response = await fetch(url + '/isUserBanned', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: IDToken
        })
      })
      let result = await response.json()

      if (result.isUserBanned) {
        Dialog.create({
          title: 'Banned User',
          message: 'This account has been banned. Please contact us for inquiries.',
          persistent: true,
          cancel: {
            message: 'Yes'
          }
        }).onOk(async ()  => {
          await firebase.auth().signOut()
        })
        return
      }

      if (!user.emailVerified) {
        Dialog.create({
          title: 'Email Not Verified',
          message: 'Welcome to Zerudite! Before you can log in, we would like you to verify your email. Please check your email for a verification email. Click on the link, then verify your email. Upon doing so, you will be able to log in.',
          persistent: true,
        }).onOk(async ()  => {
          await firebase.auth().signOut()
          await this.$router.replace('/login')
        })
      }
      else {
        await this.$router.push('/profile')
      }
    },
    forgotPasswordDialog() {
      this.forgotDialog = true
    },
    async resetPassword() {
      try {
        await firebase.auth().sendPasswordResetEmail(this.forgotEmail)
        Dialog.create({
          title: 'Password Reset',
          message: 'Your password was reset. Please check your email for further instructions to set a new password.',
          persistent: true,
        }).onOk(async ()  => {
          await firebase.auth().signOut()
        })
      } catch (e) {
        Dialog.create({
          title: 'Password Not Reset',
          message: 'Your password was not reset. Please try again. Error: ' + e.message,
          persistent: true,
        }).onOk(async ()  => {
          await firebase.auth().signOut()
        })
      }
    }

  },
  async mounted () {
    await firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        await this.$router.push('/profile')
      }
    })
  },
  name: "Login",
  components: {
    NavBar
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gentium+Basic:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@font-face {
  font-family: "Farray";
  src: url("../assets/fonts/FARRAY.otf");
}

.login-header {
  background-image: url("../assets/loginbkg.png");
  background-size: cover;
  height: 92vh;
}

.login-image {
  background-image: url("../assets/tutoring-image-loginpage.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 20px 0 0 20px;
}

.login-dialog {
  background-color: black;
  border-radius: 0 20px 20px 0;
}

.lato {
  font-family: Lato;
}


</style>
<style>

.q-field__messages {
  color: teal !important;
}

.text-negative {
  color: teal !important;
}
</style>
